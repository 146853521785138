<template>
  <div class="person-center">
    <div class="avater">
      <div style="display: flex; align-items: center; padding: 40px 20px 0">
        <div class="avater-img">
          <img src="@/assets/icon-avatar.png" alt="" />
        </div>
        <span class="user-name">{{
          mobile.replace(/^(.{3})(?:\d+)(.{4})$/, "$1****$2")
        }}</span>
      </div>

      <span class="logoff" @click="onLogoff">注销</span>
    </div>

    <div class="menu-container">
      <div class="menu">
        <div class="menu-header">
          <span class="bold">我的订单</span>
          <div class="menu-header-right" @click="jumpOrderList(-1)">
            <span>全部订单</span>
            <img
              class="icon-right"
              src="@/assets/icon-right-default.png"
              alt=""
            />
          </div>
        </div>
        <ul class="menu-ul">
          <li
            class="menu-li"
            v-for="item in orderList"
            :key="item.title"
            @click="jumpOrderList(item.status)"
          >
            <img class="menu-img" :src="item.icon" alt="" />
            <span class="menu-title">{{ item.title }}</span>
          </li>
        </ul>
      </div>

      <ul class="menu-list">
        <li class="menu-list-item" @click="jumpInvoice">
          <div class="menu-list-item-left">
            <img
              class="menu-list-item-icon"
              src="@/assets/user/icon_service_fapiao.png"
              alt=""
            />
            <span class="menu-list-item-title">申请发票</span>
          </div>
          <img
            class="icon-right"
            src="@/assets/icon-right-default.png"
            alt=""
          />
        </li>
        <li v-if="hospitalInfo.openReadReport" class="menu-list-item" @click="jumpReport">
          <div class="menu-list-item-left">
            <img
              class="menu-list-item-icon"
              src="@/assets/user/icon_service_report.png"
              alt=""
            />
            <span class="menu-list-item-title">查看报告</span>
          </div>
          <img
            class="icon-right"
            src="@/assets/icon-right-default.png"
            alt=""
          />
        </li>
        <li class="menu-list-item" @click="callPhone">
          <div class="menu-list-item-left">
            <img
              class="menu-list-item-icon"
              src="@/assets/icon-phone.png"
              alt=""
            />
            <span class="menu-list-item-title">在线客服</span>
          </div>
          <img
            class="icon-right"
            src="@/assets/icon-right-default.png"
            alt=""
          />
        </li>
      </ul>

      <div class="logout" @click="logout">退出登录</div>
    </div>
  </div>
</template>
<script>
import { GetHospital, logoff } from "@/api";
import { Dialog } from "vant";

export default {
  name: "person-center",
  computed: {
    mobile() {
      return this.$store.state.mobile;
    },
    telNo() {
      return localStorage.getItem("telNo") || "";
    },

  },
  data() {
    const hospitalId =  this.$store.state.hospitalId;
    return {
      hospitalInfo:{},
      orderList: [
        {
          icon: require("@/assets/user/icon_order_fukuan.png"),
          title: "待付款",
          status: 0,
        },
        {
          icon: require("@/assets/user/icon_order_yiyuyue.png"),
          title: "已预约",
          status: 3,
        },
        {
          icon: require("@/assets/user/icon_order_shouhou.png"),
          title: "售后",
          status: 4,
        },
      ],
      phoneNo: hospitalId === '12492' ? "0631-3806868" :"4009003732",
      message: hospitalId === '12492' ? "请拨打0631-3806868到医院体检中心人工咨询（上午8:00-11:30，下午1:30-5:30，法定节假日除外）" : "请拨打4009003732转2到体检后转0到人工（周一到周日8:00-20:00)",
    };
  },
  created() {
    this.$setTitle("个人中心");
    this.$store.commit("setTitle", "个人中心");
    if (this.$route.query.hospitalId) {
      this.$store.commit("setHospitalId", this.$route.query.hospitalId);
      localStorage.setItem("hospitalId", this.$route.query.hospitalId);
      this.getHospital();
    }
  },
  methods: {
    async getHospital() {
      let data = await GetHospital({
        HospitalId: this.$store.state.hospitalId,
        HospitalCode: this.$store.state.hospitalId,
      });
      this.hospitalInfo = data;
      this.$store.commit("setHospitalInfo", data);
      localStorage.setItem("hospitalInfo", JSON.stringify(data));
      localStorage.setItem("telNo", data.telNo);
      return data;
    },
    jumpOrderList(status) {
      this.$router.push({
        name: "order-list",
        params: {
          status,
        },
      });
    },
    jumpReport() {
      this.$router.push("report-list");
    },
    jumpInvoice() {
      this.$router.push("invoice");
    },
    logout() {
      Dialog.confirm({
        title: "退出登录",
        message: "是否确定退出登录？",
        confirmButtonColor: "#00c4b3",
      })
        .then(async () => {
          this.$store.commit("setToken", "");
          this.$store.commit("setMobile", "");
        })
        .catch(() => {
          // on cancel
        });
    },
    callPhone() {
      Dialog.confirm({
        message: this.message,
        confirmButtonColor: "#00c4b3",
      })
        .then(() => {
          // on confirm
          window.location.href = `tel:${this.phoneNo}`;
        })
        .catch(() => {
          // on cancel
        });
    },
    async onLogoff() {
      Dialog.confirm({
        title: "注销账号",
        message:
          "注销账号后，该账号下的全部信息数据将会被清空，请确认是否注销？",
        confirmButtonColor: "#00c4b3",
      })
        .then(async () => {
          const res = await logoff();
          this.$store.commit("setToken", "");
          this.$store.commit("setMobile", "");
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="scss">
.person-center {
  flex: 1;
  overflow: hidden;
  background-color: #f4f4f4;
  .avater {
    position: relative;
    background: url("../assets/user-bg.jpg") no-repeat top left;
    background-size: 100%;
    height: 120px;
    font-size: 20px;
    .avater-img {
      width: 60px;
      height: 60px;
      border-radius: 30px;
      overflow: hidden;
      margin-right: 10px;
    }
    .user-name {
      color: #fff;
      font-weight: bold;
      font-family: PingFang SC-Bold, PingFang SC;
    }
  }
}

.menu-container {
  margin-top: 10px;
  padding: 0 10px;
}

.menu {
  margin-bottom: 10px;
  padding: 0 10px 15px 10px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color: #2c2f37;
  height: 43px;
}

.menu-header-right {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 15px;
}

.icon-right {
  width: 4px;
  height: 8px;
  margin-left: 4px;
}

.menu-ul {
  display: flex;
  align-items: center;
}

.menu-li {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.33%;
  flex-direction: column;
}

.menu-img {
  width: 32px;
  height: 32px;
}

.menu-title {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #475266;
  line-height: 16px;
}

.menu-list {
  margin-top: 10px;
  border-radius: 10px;
  background-color: #fff;
}

.menu-list-item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 54px;
}

.menu-list-item:not(:first-child)::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #edeff0;
  transform: scaleY(50%);
}

.menu-list-item-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-list-item-icon {
  width: 24px;
  height: 24px;
}

.menu-list-item-title {
  margin-left: 10px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #475266;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  height: 50px;
  background-color: #fff;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #2c2f37;
}

.logoff {
  position: absolute;
  right: 0;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
  font-size: 12px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.2);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
</style>
